import React, { useState, useEffect, useCallback } from 'react';
import "../assets/fonts/fontawesome-free/css/all.min.css"
import "../assets/css/sb-admin-2.min.css"
import Sidebar from "../components/userDashboard/Sidebar";
import Topbar from "../components/userDashboard/Topbar";
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import loader1 from '../assets/images/03-42-11-849_512.gif'
import OnboardForm from '../components/userDashboard/OnboardForm';
import MainView from '../components/userDashboard/MainView';
import PageUnderCreation from '../components/userDashboard/utilityComponents/PageUnderCreation';
import CreateSwapForm from '../components/userDashboard/CreateSwap';
import ViewSwapOffers from '../components/userDashboard/ViewOffers';
import WatchedLessons from '../components/userDashboard/WatchedLessons';
import MyCerts from '../components/userDashboard/MyCerts';

const Dashboard = () => {
    const [userData, setUserData] = useState(null);
    const [currentView, setCurrentView] = useState("home")
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    
    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const navigate = useNavigate();
    const fetchUserData = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login', { state: { serverError: 'Please log in to access the dashboard.' } });
            }
            //console.log("This is the token",token)
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`, // Include JWT token in Authorization header
                  'Content-Type': 'application/json', // Optional, depending on your API
                }
            };
             //console.log("This is the token 1",token)
            const response = await axios.get('https://www.opecstat.com/opecstat_server/user/getUser', config);
             //console.log("This is the token 2",token)
            //console.log(response.data.user.name)
            if(!response.status || response.status !== 200){
                navigate('/login', { state: { serverError: 'Please log in to access the dashboard.' } });
            }
            //alert(response.status)
            setUserData(response.data.user); // Set user data received from backend
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Token expired or unauthorized
                navigate('/login', { state: { serverError: 'Session expired. Please log in again.' } });
                //console.log('yes')
                //alert("Your network is unstable")
            } else {
                console.error('Error fetching user data:', error);
                navigate('/login', { state: { serverError: 'An error occurred. Please log in again.' } });
                //alert("Your connection is unstable")
            }
        }
      },[navigate])
    useEffect(() => {
      fetchUserData();
    }, [fetchUserData]);

    useEffect(() => {
        if(userData && userData.formFilled===0){
            setCurrentView("onboard")
        }
    }, [userData]);

    const renderView = (view) =>{
        setCurrentView(view)
    }
    const getViewComponent = () => {
        switch (currentView) {
            case 'onboard':
                return <OnboardForm userData={userData} onProfileUpdate={fetchUserData} screenWidth={screenWidth}/>;
            case 'home':
                return <MainView renderView={renderView} userData={userData}/>
            case 'puc':
                return <PageUnderCreation renderView={renderView} />
            case 'create_swap':
                return <CreateSwapForm userData={userData} onProfileUpdate={fetchUserData} screenWidth={screenWidth}/>
            case 'view_offers':
                return <ViewSwapOffers userData={userData} onProfileUpdate={fetchUserData} screenWidth={screenWidth}/>
            case 'watched_lessons':
                return <WatchedLessons userData={userData}/>
            case 'my_certs':
                return <MyCerts userData={userData}/>
            default:
                return <OnboardForm userData={userData} onProfileUpdate={fetchUserData} screenWidth={screenWidth}/>;
        }
      };

    return ( 
        <>
            {!userData && <center><div className='h-screen w-full bg-slate-600 text-dark text-5xl'><img src={loader1} alt='loader' /><br/>Loading Your Dashboard...</div></center>}
            {
                userData &&
                <div id="page-top">

                    {/*<!-- Page Wrapper -->*/}
                    <div id="wrapper">

                        <Sidebar  renderView={renderView} userData={userData}/>

                        {/*<!-- Content Wrapper -->*/}
                        <div id="content-wrapper" class="d-flex flex-column">

                            {/*<!-- Main Content -->*/}
                            <div id="content">

                                {/*<!-- Topbar -->*/}
                                    <Topbar userData={userData} onProfileUpdate={fetchUserData} renderView={renderView}/>
                                {/*<!-- End of Topbar -->*/}

                                {/*<!-- Begin Page Content -->*/}
                                <div class="container-fluid">

                                    {getViewComponent()}

                                </div>
                                {/*<!-- /.container-fluid -->*/}

                            </div>
                            {/*<!-- End of Main Content -->*/}

                            {/*<!-- Footer -->*/}
                            <footer class="sticky-footer bg-white">
                                <div class="container my-auto">
                                    <div class="copyright text-center my-auto">
                                        <span>Copyright &copy; Opecstat</span>
                                    </div>
                                </div>
                            </footer>
                            {/*<!-- End of Footer -->*/}

                        </div>
                        {/*<!-- End of Content Wrapper -->*/}

                    </div>
                    {/*<!-- End of Page Wrapper -->*/}

                    {/*<!-- Scroll to Top Button-->*/}
                    <a class="scroll-to-top rounded" href="#page-top">
                        <i class="fas fa-angle-up"></i>
                    </a>

                    {/*<!-- Logout Modal-->*/}
                    <div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div class="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                                <div class="modal-footer">
                                    <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                                    <a class="btn btn-primary" href="login.html">Logout</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            }
        </>

     );
}
 
export default Dashboard;