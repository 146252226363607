import React, { useState } from "react";
import ResourceControlComponent from "./ResourceControl";
import { useNavigate } from "react-router-dom";

const MarketingEnrollment = ({userData}) =>{
    const [isActivating, setisActivating] = useState(false)
    const navigate = useNavigate()

    const handleActivate = async () =>{
        if(isActivating) return false;
        setisActivating(true)
        try {
            const response = await fetch(
                `https://opecstat.com/opecstat_server/user/freeEnroll/${userData._id}/${userData.intend_courses_id[0]._id}`)
            const data = await response.json();
            if (response.ok) {
                console.log("Actiate Program Data ",JSON.stringify(data,null,2))
                navigate(0)
            }
        } catch (error) {
            console.error('Error fetching previous quiz data:', error);
        }
    }

    /*const handleActivate80 = async () => {
        if (isActivating) return; // Prevent multiple clicks
        setisActivating(true);
    
        try {
            // Check BatchTracker status via the API
            const trackerResponse = await fetch('https://opecstat.com/opecstat_server/course/batch-tracker/toggle-status', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code: 'unique_code_for_tracker', days: 3 }),
            });
    
            let word = 'Begin'; // Default to true if there is an error
    
            if (trackerResponse.ok) {
                const trackerData = await trackerResponse.json();
                word = trackerData.batchTracker.status ? 'Begin' : 'Activate';
                //alert(word)
            } else {
                console.error('Failed to check BatchTracker status:', trackerResponse.statusText);
            }
            
            // Make the API call
            const response = await fetch(
                `https://opecstat.com/opecstat_server/user/freeEnroll/${userData._id}/66e6f9b6c1eda0933df58c63`
            );
            const data = await response.json();
    
            if (response.ok) {
                console.log(`${word} Program Data:`, JSON.stringify(data, null, 2));
            } else {
                console.error(`Failed to ${word.toLowerCase()} program:`, data);
            }
    
            // Open WhatsApp link with the appropriate word
            window.location.href = `https://api.whatsapp.com/send?phone=2348112691532&text=I%20want%20to%20${word}%20my%20Program`;
        } catch (error) {
            console.error('Error handling activation:', error);
    
            // Default to "Begin" if there's an error in checking
            window.location.href = 'https://api.whatsapp.com/send?phone=2348112691532&text=I%20want%20to%20Begin%20my%20Program';
        } finally {
            setisActivating(false);
        }
    };*/

    const handleActivate80 = async () => {
        if (isActivating) return; // Prevent multiple clicks
        setisActivating(true);
    
        try {
            // Check BatchTracker status via the API
            const trackerResponse = await fetch('https://opecstat.com/opecstat_server/course/batch-tracker/toggle-status', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code: 'unique_code_for_tracker', days: 3 }),
            });
    
            let word = 'Begin'; // Default to "Begin" if there is an error
    
            if (trackerResponse.ok) {
                const trackerData = await trackerResponse.json();
                word = trackerData.batchTracker.status ? 'Begin' : 'Activate';
            } else {
                console.error('Failed to check BatchTracker status:', trackerResponse.statusText);
            }
    
            // Make the API call to check or create the activation code
            const activationResponse = await fetch('https://opecstat.com/opecstat_server/user/check-activation-code', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: userData._id,
                    courseId: userData.intend_courses_id[0]._id,
                }),
            });
    
            if (!activationResponse.ok) {
                console.error('Failed to fetch activation code:', activationResponse.statusText);
                throw new Error('Unable to fetch activation code');
            }
    
            const activationData = await activationResponse.json();
            const activationCode = activationData.activation.code;
    
            // Open WhatsApp link with the activation code
            const whatsappMessage = `I want to ${word} my Program and course code is ${userData.intend_courses_id[0].course_code}`;
            window.location.href = `https://api.whatsapp.com/send?phone=2348112691532&text=${encodeURIComponent(whatsappMessage)}`;
        } catch (error) {
            console.error('Error handling activation:', error);
    
            // Default to "Begin" with a generic WhatsApp message if there's an error
            window.location.href = 'https://api.whatsapp.com/send?phone=2348112691532&text=I%20want%20to%20Begin%20my%20Program';
        } finally {
            setisActivating(false);
        }
    };
    
    

    const handleActivate90 = async () => {
        if (isActivating) return; // Prevent multiple clicks
        setisActivating(true);
        
    
        try {
            // Make the API call to check or create the activation code
            const activationResponse = await fetch('https://opecstat.com/opecstat_server/user/check-activation-code', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: userData._id,
                    courseId: userData.intend_courses_id[0]._id,
                }),
            });
    
            if (!activationResponse.ok) {
                console.error('Failed to fetch activation code:', activationResponse.statusText);
                throw new Error('Unable to fetch activation code');
            }
    
            const activationData = await activationResponse.json();
            const activationCode = activationData.activation.code;

            // Make the API call
            const response = await fetch(
                `https://opecstat.com/opecstat_server/user/freeEnrollAfterPay/${userData._id}/${userData.intend_courses_id[0]._id}`
            );
            const data = await response.json();
    
            if (response.ok) {
                console.log('Activate Program Data:', JSON.stringify(data, null, 2));
            } else {
                console.error('Failed to activate program:', data);
            }
        } catch (error) {
            console.error('Error activating program:', error);
        } finally {
            // Open WhatsApp link regardless of API call outcome
            const whatsappMessage90 = `I want to begin my program and course code is ${userData.intend_courses_id[0].course_code} `
            window.location.href = `https://api.whatsapp.com/send?phone=2348112691532&text=${encodeURIComponent(whatsappMessage90)}`;
            setisActivating(false);
        }
    };
    
      
    
    return(
        <center>
            <div className="w-100 justify-center">
            {userData.sub_status === 50 || userData.sub_status === 60 ? (
                <>
                
                    <div className="card bg-secondary text-center">
                        <div className="card-body">
                            <b className="text-white">
                            {userData.sub_status === 50
                                ? "You are currently disenrolled and Cannot Participate in this program..."
                                : "You have successfully completed the Python Module of this program. To move into the Web Development Module, please make the required payment for your certificate."}
                            <br />
                            <br />
                            To resume your program: 
                            <br />
                            <br />
                            <div className="d-flex justify-content-center">
                                <div className="w-50">
                                <ResourceControlComponent
                                    videoData={null}
                                    userData={userData}
                                    type="subscribe"
                                />
                                </div>
                            </div>
                            <br />
                            <br />
                            <div className="text-danger bg-white p-1 rounded-sm">
                                Kindly note that the payment goes back to &#8358;67,000 soon
                            </div>
                            </b>
                        
                        </div>
                    </div>
                </>
            ) : (
                <i></i>
            )}

            
            { userData.intend_courses_id.length > 0 ?
                (
                    userData.sub_status === 70 ? (
                        <>
                            <div className="card  bg-outline text-center">
                                <div className="card-body">
                                    <b className="">
                                    Kindly send a screenshot of your dashboard to the group.<br/>Your program would be activated by your Facilitator: 
                                    <br />
                                    {/*<br />
                                    <div className="d-flex justify-content-center">
                                        <div className="w-50">
                                            <button className={`btn btn-primary btn-lg ${isActivating ? 'disabled' : ''}`} onClick={handleActivate}>{isActivating ? 'Activating Program...' : 'Activate Program'}</button>
                                        </div>
                                    </div>
                                    <br/>*/}
                                    </b>
                                
                                </div>
                            </div>
                        </> 
                ): userData.sub_status === 80 ? (
                    <>
                        <div className="card  bg-outline text-center">
                            <div className="card-body">
                                <b className="">
                                    Your {userData.intend_courses_id[0].title} Program is not yet Active<br/><br/>
                                    Click the button below and follow the instructrions to Activate your Program: 
                                <br />
                                <br />
                                <div className="d-flex justify-content-center">
                                    <div className="w-50">
                                        <button className={`btn btn-primary btn-lg ${isActivating ? 'disabled' : ''}`} onClick={handleActivate80}>{isActivating ? 'Activating Program...' : 'Activate Program'}</button>
                                    </div>
                                </div>
                                <br/>
                                </b>
                            
                            </div>
                        </div>
                    </> 
                ): userData.sub_status === 90 ? (
                    <>
                        <div className="card  bg-outline text-center">
                            <div className="card-body">
                                <b className="">
                                    Your {userData.intend_courses_id[0].title} Program is not yet Active<br/><br/>
                                    Click the button below and follow the instructrions to Activate your Program: 
                                <br />
                                <br />
                                <div className="d-flex justify-content-center">
                                    <div className="w-50">
                                        <button className={`btn btn-primary btn-lg ${isActivating ? 'disabled' : ''}`} onClick={handleActivate90}>{isActivating ? 'Activating Program...' : 'Activate Program'}</button>
                                    </div>
                                </div>
                                <br/>
                                </b>
                            
                            </div>
                        </div>
                    </> 
                ):(
                    <i></i>
                )
            ) : (
                <i></i>
            )}
            </div>
        </center>
    )
}

export default MarketingEnrollment