import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import YouTubeEmbed from './utilityComponents/YoutubeEmbed';
import logo from '../../assets/images/opec10.png';
import youtube_logo from '../../assets/images/youtube_logo.png';
import instagram_logo from '../../assets/images/instagram_logo.png';
import useFetch from '../custom_hooks/useFetch';
import ResourceControlComponent from './utilityComponents/ResourceControl';
import LogoutButton from '../auth/Logout';

const VideoList = ({ currentVideoId, allVideos, combinedCourses, onVideoSelect, isDisabled }) => {
    const showTestNotification = () => {
        alert("Please take the test attached to this lesson first before moving to another lesson.");
    }

    return (
        <div className="video-list overflow-auto">
            {allVideos && allVideos.map(video => (
                <div key={video._id}>
                    <div
                        className={`video-item ${currentVideoId === video._id ? 'text-success' : 'text-secondary'} fw-bold cursor-pointer`}
                        onClick={isDisabled ? () => onVideoSelect(video) : showTestNotification}
                    >
                        <i className={`${currentVideoId === video._id ? 'fa fa-fw fa-check-circle' : 'fa fa-fw fa-video'}`}></i> 
                        {video.title}
                    </div>
                    <hr />
                </div>
            ))}

            {/* Uncomment and adjust for combinedCourses if needed */}
            {/* 
            {combinedCourses.map(course => (
                <React.Fragment key={course._id}>
                    <h6 className="text-muted mt-3">{course.title}</h6>
                    {course.videos.map(video => (
                        <div
                            key={video._id}
                            className={`video-item ${currentVideoId === video._id ? 'active-video' : ''}`}
                            onClick={() => onVideoSelect(video._id)}
                        >
                            {video.title}
                        </div>
                    ))}
                </React.Fragment>
            ))}
            */}
        </div>
    );
};
/*const VideoList = ({ currentVideoId, allVideos, combinedCourses, onVideoSelect, isDisabled }) => {
    const showTestNotification = () => {
        alert("Please take the test attached to this lesson first before moving to another lesson.");
    };

    return (
        <div className="video-list overflow-auto">
            {allVideos &&
                allVideos.reduce((acc, video, index) => {
                    // Track the last main_Course_id._id
                    const lastVideo = index > 0 ? allVideos[index - 1] : null;
                    const isNewModule =
                        !lastVideo || video.main_course_id._id !== lastVideo.main_course_id._id;

                    if (isNewModule) {
                        // Calculate module number
                        const moduleNumber =
                            acc.filter(item => item.type === "module").length + 1;

                        // Add module header to the accumulator
                        acc.push({
                            type: "module",
                            moduleNumber,
                        });
                    }

                    // Add video item to the accumulator
                    acc.push({
                        type: "video",
                        video,
                    });

                    return acc;
                }, []).map((item, index) => {
                    if (item.type === "module") {
                        return (
                            <h5 key={`module-${index}`} className="text-primary mt-4">
                                Module {item.moduleNumber}
                            </h5>
                        );
                    }

                    if (item.type === "video") {
                        const video = item.video;
                        return (
                            <div key={video._id}>
                                <div
                                    className={`video-item ${
                                        currentVideoId === video._id ? "text-success" : "text-secondary"
                                    } fw-bold cursor-pointer`}
                                    onClick={
                                        isDisabled
                                            ? () => onVideoSelect(video)
                                            : showTestNotification
                                    }
                                >
                                    <i
                                        className={`${
                                            currentVideoId === video._id
                                                ? "fa fa-fw fa-check-circle"
                                                : "fa fa-fw fa-video"
                                        }`}
                                    ></i>{" "}
                                    {video.title}
                                </div>
                                <hr />
                            </div>
                        );
                    }

                    return null;
                })}
        </div>
    );
};
*/


const LessonView = ({ renderView, userData, videoData, mainCId }) => {
    const[dVideoData, setDVideoData] = useState(videoData)
    const [videoId, setVideoId] = useState(null);
    const [courseVideos, setCourseVideos] = useState([]);
    const [combinedCourses, setCombinedCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [testTaken, setTestTaken] = useState(false)
    const [isCourseShared, setIsCourseShared] = useState(false)
    const { result, isResultPending, resultError } = useFetch(videoData ? `https://opecstat.com/opecstat_server/video/getVideosByMainCourseId/${mainCId}` : null);
    const [isActivating, setisActivating] = useState(false)
    const [idToActivate, setIdToActivate] = useState(null)
    const [isVideoUpdated, setIsVideoUpdated] = useState(false);
    const [error, setError] = useState(null);

    
    useEffect(() => {
        setIsLoading(false)
        if (videoData && videoData._id) {
            setDVideoData(videoData)
            setVideoId(videoData._id);
            setTestTaken(false)

            const updateVideoWatched = async () => {
                try {
                    // Check if videoData is null before proceeding
                    if (!videoData) {
                        setError('Video data is missing.');
                        setTimeout(() => setError(null), 3000); // Hide the error message after 3 seconds
                        return;  // Return early if videoData is null
                    }
    
                    // Wait for 20 seconds before making the request
                    setTimeout(async () => {
                        const response = await fetch('https://opecstat.com/opecstat_server/user/add-video', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                userId: userData._id,
                                videoId: videoData._id,
                            }),
                        });
    
                        // Ensure the response is in JSON format before trying to parse
                        const jsonResponse = await response.json();  // Get raw response text
    
                        try {
                            const data = jsonResponse//JSON.parse(textResponse);  // Try to parse the text into JSON
                            //console.log("Update Response: ", JSON.stringify(data, null, 2));
    
                            if (response.ok) {
                                setIsVideoUpdated(true);
                                setTimeout(() => setIsVideoUpdated(false), 3000); // Hide the success message after 3 seconds
                            } else {
                                setError(data.message || 'Error updating video');
                                setTimeout(() => setError(null), 3000); // Hide the error message after 3 seconds
                            }
                        } catch (parseError) {
                            console.error('Failed to parse JSON:', parseError);
                            setError('Invalid response format from the server');
                            setTimeout(() => setError(null), 3000); // Hide the error message after 3 seconds
                        }
                    }, 2000);  // 20 seconds delay
                } catch (error) {
                    console.error('Error updating video:', error);
                    setError('Error updating video');
                    setTimeout(() => setError(null), 3000); // Hide the error message after 3 seconds
                }
            };
    
    
            // Trigger the video update function
            updateVideoWatched();
        }

        // Check if the user has previously taken this quiz
        (async () => {
            try {
            const response = await fetch(
                `https://opecstat.com/opecstat_server/quiz/getQuizByVideoId/${videoData._id}`
            );
            const data = await response.json();
            //console.log("The Data", JSON.stringify(response,null,2))
            if (response.ok) {
                console.error("Taken Data ",JSON.stringify(data,null,2))
            }else{
                setTestTaken(true)
            }
            } catch (error) {
                //console.error('Error fetching previous quiz data:', error);
                setTestTaken(true)
            }
        })();

        // Check if the user has previously taken this quiz
        (async () => {
            try {
            const response = await fetch(
                `https://opecstat.com/opecstat_server/quiz/getQuizByUserAndVideoId/${userData._id}/${videoData._id}`
            );
            const data = await response.json();
            if (response.ok && data.quizFoundStatus) {
                setTestTaken(true)
                console.error("Taken Data ",JSON.stringify(data,null,2))
            }
            } catch (error) {
                //console.error('Error fetching previous quiz data:', error);
                setTestTaken(true)
            }
        })();
        

        if(userData.sub_status === 2 && userData.quizzes_taken.length >= 22){
            // Close Course
            (async () => {
                try {
                const response = await fetch(
                    `https://opecstat.com/opecstat_server/user/closeCourse/${userData._id}`
                );
                const data = await response.json();
                if (response.ok) {
                    //console.error("Taken Data ",JSON.stringify(data,null,2))
                }
                } catch (error) {
                //console.error('Error fetching previous quiz data:', error);
                }
            })();

        }
        //console.log("Main: ",JSON.stringify(videoData.main_course_id,null,2))
        // Check if course has been shared
        (async () => {
            try {
                const response = await fetch(
                    `https://opecstat.com/opecstat_server/course/check-course-shared/${userData._id}/${videoData.main_course_id._id}`
                );
                const data = await response.json();
                //console.log("Check: ",JSON.stringify(data,null,2))
                if (response.ok) {
                    if(data.courseId === "66e6f951c1eda0933df58864"){
                        setIsCourseShared(true)
                    }else{
                        setIsCourseShared(data.isShared)
                    } 
                    setIdToActivate(data.courseId)
                }
            } catch (error) {
                console.error('Error fetching previous check course:', error);
                setIsCourseShared(true)
            }
        })();
        
    }, [videoData]);

    useEffect(() => {
        //console.log("changed")
        //console.log("Vidio: ", dVideoData)
        const updateVideoWatched = async () => {
            try {
                // Check if videoData is null before proceeding
                if (!dVideoData) {
                    setError('Video data is missing.');
                    //setTimeout(() => setError(null), 3000); // Hide the error message after 3 seconds
                    return;  // Return early if videoData is null
                }

                // Wait for 20 seconds before making the request
                setTimeout(async () => {
                    const response = await fetch('https://opecstat.com/opecstat_server/user/add-video', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            userId: userData._id,
                            videoId: dVideoData._id,
                        }),
                    });

                    // Ensure the response is in JSON format before trying to parse
                    const jsonResponse = await response.json();  // Get raw response text

                    try {
                        const data = jsonResponse;  // Try to parse the text into JSON
                        //console.log("Update Response 2: ", JSON.stringify(data, null, 2));

                        if (response.ok) {
                            setIsVideoUpdated(true);
                            //setTimeout(() => setIsVideoUpdated(false), 3000); // Hide the success message after 3 seconds
                        } else {
                            setError(data.message || 'Error updating video');
                            //setTimeout(() => setError(null), 3000); // Hide the error message after 3 seconds
                        }
                    } catch (parseError) {
                        console.error('Failed to parse JSON:', parseError);
                        setError('Invalid response format from the server');
                        //setTimeout(() => setError(null), 3000); // Hide the error message after 3 seconds
                    }
                }, 1000);  // 20 seconds delay
            } catch (error) {
                console.error('Error updating video:', error);
                setError('Error updating video');
                //setTimeout(() => setError(null), 3000); // Hide the error message after 3 seconds
            }
        };


        // Trigger the video update function
        updateVideoWatched();
    
        // Fetch main course videos and combined course videos when dVideoData is loaded
        if (dVideoData && dVideoData.courseVideos && dVideoData.combinedCourses) {
            setCourseVideos(dVideoData.courseVideos);
            setCombinedCourses(dVideoData.combinedCourses);
        }

        

        // Check if the user has previously taken this quiz
        (async () => {
            try {
            const response = await fetch(
                `https://opecstat.com/opecstat_server/quiz/getQuizByVideoId/${videoData._id}`
            );
            const data = await response.json();
            //console.log("The Data", JSON.stringify(response,null,2))
            if (response.ok) {
                console.error("Taken Data ",JSON.stringify(data,null,2))
            }else{
                setTestTaken(true)
            }
            } catch (error) {
                //console.error('Error fetching previous quiz data:', error);
                setTestTaken(true)
            }
        })();

        // Check if the user has previously taken this quiz
        (async () => {
            try {
            const response = await fetch(
                `https://opecstat.com/opecstat_server/quiz/getQuizByUserAndVideoId/${userData._id}/${videoData._id}`
            );
            const data = await response.json();
            if (response.ok && data.quizFoundStatus) {
                setTestTaken(true)
                console.error("Taken Data ",JSON.stringify(data,null,2))
            }
            } catch (error) {
                //console.error('Error fetching previous quiz data:', error);
                setTestTaken(true)
            }
        })();

        //console.log("Current Course Length ",JSON.stringify(userData.quizzes_taken.length,null,2))

        if(userData.sub_status === 2  && userData.quizzes_taken.length >= 22){
            // Close Course
            (async () => {
                try {
                const response = await fetch(
                    `https://opecstat.com/opecstat_server/user/closeCourse/${userData._id}`
                );
                const data = await response.json();
                if (response.ok) {
                    console.error("Taken Data ",JSON.stringify(data,null,2))
                }
                } catch (error) {
                //console.error('Error fetching previous quiz data:', error);
                }
            })();

        }

         // Check if course has been shared
         (async () => {
            try {
                const response = await fetch(
                    `https://opecstat.com/opecstat_server/course/check-course-shared/${userData._id}/${dVideoData.main_course_id}`
                );
                const data = await response.json();
                //console.log("2 Check: ",JSON.stringify(dVideoData,null,2))
                if (response.ok) {
                    if(data.courseId === "66e6f951c1eda0933df58864"){
                        setIsCourseShared(true)
                    }else{
                        setIsCourseShared(data.isShared)
                    } 
                    setIdToActivate(data.courseId)
                }
            } catch (error) {
                console.error('Error fetching previous check course:', error);
                setIsCourseShared(true)
            }
        })();
    }, [dVideoData]);

    const handleVideoSelect = (selectedVideo) => {
        setIsLoading(true)
        //console.log(JSON.stringify(selectedVideo,null,2))
        try{
            setVideoId(selectedVideo._id);
            setDVideoData(selectedVideo)
            setTestTaken(false)
        }catch(e){
            console.log("e: ", e)
        }
        setIsLoading(false)
    };

    const handleActivate = async (MCourseId) => {
        if (isActivating) return; // Prevent multiple clicks
        setisActivating(true);
    
        try {
            // Make the API call to check or create the activation code
            const activationResponse = await fetch('https://opecstat.com/opecstat_server/user/check-activation-code', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: userData._id,
                    courseId: MCourseId,
                }),
            });
    
            if (!activationResponse.ok) {
                console.error('Failed to fetch activation code:', activationResponse.statusText);
                throw new Error('Unable to fetch activation code');
            }
    
            const activationData = await activationResponse.json();
            const activationCode = activationData.activation.code;
            // Make the API call
            const response = await fetch(
                `https://opecstat.com/opecstat_server/user/update-course-shared/${userData._id}/${MCourseId}`
            );
    
            // Debug: Check response status and headers
            //console.log('Response status:', response.status);
            //console.log('Response headers:', response.headers);
    
            const contentType = response.headers.get('content-type');
            if (response.ok) {
                let data;
                if (contentType && contentType.includes('application/json')) {
                    data = await response.json(); // Parse as JSON
                } else {
                    data = await response.text(); // Parse as plain text
                }
                //console.log('Activate Program Data:', data);
            } else {
                const errorMessage = contentType.includes('application/json')
                    ? await response.json()
                    : await response.text();
                console.error('Failed to activate program:', errorMessage);
            }
        } catch (error) {
            console.error('Error activating program:', error);
        } finally {
            setTimeout(() => {
                // Uncomment this line if required for redirection
                const whatsappMessage90 = `I want to begin my next module and course code is ${videoData.main_course_id.course_code} `
                window.location.href = `https://api.whatsapp.com/send?phone=2348112691532&text=${encodeURIComponent(whatsappMessage90)}`;
            }, 3000);
            //setisActivating(false);
        }
    };
    

    const handleNavClick = () =>{
        setIsLoading(true)
    }

    const handleTestTaken = (value) =>{
        setTestTaken(value)
    }

    const showTestNotification = () =>{
        alert("Please take the quiz attatched to this lesson first before moving to another lesson")
    }

    return (
        <>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <Link to="/dashboard" className='btn btn-sm btn-primary'><span className='fa fa-fw fa-arrow-left'></span> Back to dashboard </Link><a href="mailto:support@opecstat.com" className='btn btn-sm btn-secondary'><span className='fa fa-fw fa-support'></span> Technical Support </a><LogoutButton/>
            </div>
            <div className='mb-2'>
                {dVideoData && <h1 className="h3 mb-0 text-gray-800 fw-bold">{dVideoData.title.toUpperCase()}</h1>}
            </div>
            {
                dVideoData && 
                <div className='row mb-2'>
                    <div className='col-md-3'>
                        <ResourceControlComponent videoData={dVideoData} userData={userData} type="textbook" />
                    </div>
                    <div className='col-md-3'>
                        <ResourceControlComponent videoData={dVideoData} userData={userData} type="quiz" handleTestTaken={handleTestTaken} />
                    </div>
                    <div className='col-md-3'>
                        <ResourceControlComponent videoData={dVideoData} userData={userData} type="assignment" />
                    </div>
                    <div className='col-md-3'>
                        <ResourceControlComponent videoData={dVideoData} userData={userData} type="chat" />
                    </div>
                </div>
            }
            
            <div className='row'>
                <div className="col-xl-8 col-lg-8">
                    <div className="card shadow mb-4">
                        <div className="card-body">
                            <div className="milestone_area">
                                {
                                    userData.sub_status === 50 || userData.sub_status === 60?(
                                        <center><img src={logo} alt='logo' style={{ width: "30%", height: "25%", opacity: "25%" }} /><br /><b className='text-dark'>{`${userData.sub_status === 50 ? 'You are currently disenrolled and Cannot Particiapte in this program...':'Congratulations on successfully completing the Python Module of this program! To proceed to the Web Development Module, kindly make the required payment for your certificate. Please note that this payment also covers all future certificates within this program' }`}<br/><br/>To resume your program: <br/><br/><span className='w-50'><ResourceControlComponent videoData={dVideoData} userData={userData} type="subscribe" /></span><br/><br/><span className='text-danger'>Kindly note that the enrollment fee goes back to &#8358;67,000 soon</span></b></center>
                                    ):(
                                        <>
                                            {!dVideoData && <center><img src={logo} alt='logo' style={{ width: "30%", height: "25%", opacity: "25%" }} /><br /><b>Loading Lesson...</b></center>}
                                            {dVideoData && isLoading && <center><img src={logo} alt='logo' style={{ width: "30%", height: "25%", opacity: "25%" }} /><br /><b>Loading Lesson...</b></center>}
                                            {dVideoData && !isLoading && !isCourseShared && <center><h3><i className='fa fa-solid fa-medal text-warning'></i> Congratulations!! <i className='fa fa-solid fa-medal text-warning'></i></h3><br/>Welcome to a the next module of the Program<br /><b>Click the button below to Activate this Module:</b><br/><div className="w-50 my-2"><button className={`btn btn-primary btn-lg ${isActivating ? 'disabled' : ''}`} onClick={()=>handleActivate(idToActivate)}>{isActivating ? 'Activating Module...' : 'Activate Module'}</button><br/></div></center>}
                                            {/*dVideoData && !isLoading && <div>{JSON.stringify(dVideoData._id)}- / -{mainCId}</div>}{/*Added the show videoId and changed the navigation*/}
                                            {/*isVideoUpdated && <p>Video marked as watched successfully!</p>*/}
                                            {/*error && <p>{error}</p>*/}
                                            {dVideoData && !isLoading && dVideoData._id === "66e7044fbf363318f77c43b5" && <div className='row'><div className='alert alert-info fw-bold'>Please note that you can download Thonny for Windows by clicking the 'Downloads' button above.</div></div>}
                                            {dVideoData && !isLoading && isCourseShared && <YouTubeEmbed videoData={dVideoData} />}
                                            {dVideoData && userData.sub_status < 4 && isCourseShared? (
                                                <center className='mt-3'>
                                                    <div className='row'>
                                                        {
                                                            testTaken ? (
                                                                <>
                                                                    <div className='col-md-6'>
                                                                        <Link 
                                                                            to={`/lesson/${typeof dVideoData.prevVideo === 'string' ? dVideoData.prevVideo : dVideoData.prevVideo?._id}/${mainCId}`} 
                                                                            onClick={() => handleNavClick()} 
                                                                            className={`w-100 btn btn-lg btn-dark ${!dVideoData.prevVideo ? 'disabled' : ''}`}
                                                                        >
                                                                            Prev.
                                                                        </Link>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <Link 
                                                                            to={`/lesson/${typeof dVideoData.nextVideo === 'string' ? dVideoData.nextVideo : dVideoData.nextVideo?._id}/${mainCId}`} 
                                                                            onClick={() => handleNavClick()} 
                                                                            className={`w-100 btn btn-lg btn-dark ${!dVideoData.nextVideo ? 'disabled' : ''}`}
                                                                        >
                                                                            Next
                                                                        </Link>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className='col-md-6'>
                                                                        <Link 
                                                                            to={`/lesson/${typeof dVideoData.prevVideo === 'string' ? dVideoData.prevVideo : dVideoData.prevVideo?._id}/${mainCId}`} 
                                                                            onClick={() => handleNavClick()} 
                                                                            className={`w-100 btn btn-lg btn-dark ${!dVideoData.prevVideo ? 'disabled' : ''}`}
                                                                        >
                                                                            Prev.
                                                                        </Link>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        {/* 
                                                                            <Link to={`/lesson/${typeof dVideoData.nextVideo === 'string' ? dVideoData.nextVideo : dVideoData.nextVideo?._id}/${mainCId}`} 
                                                                                onClick={() => handleNavClick()} 
                                                                                className={`w-100 btn btn-lg btn-dark ${!dVideoData.nextVideo ? 'disabled' : ''}`}>
                                                                                Next
                                                                            </Link> 
                                                                        */}
                                                                        <button 
                                                                            onClick={() => showTestNotification()} 
                                                                            className={`w-100 btn btn-lg btn-dark ${!dVideoData.nextVideo ? 'disabled' : ''}`}
                                                                        >
                                                                            Next
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    </div>

                                                </center>
                                            ) : null}
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                    <div className='row mb-2 p-3'>
                        <div className='col-md-6'>
                            <a href='https://www.instagram.com/opecstat_academy/' target='_blank' className='btn btn-md btn-light btn-outline-dark d-flex justify-content-center align-items-center w-100 mb-2'>
                                <img src={instagram_logo} className='w-25 h-25 mr-2'/> Follow us
                            </a>
                        </div>
                        <div className='col-md-6'>
                            <a href='https://www.youtube.com/@opecstat?sub_confirmation=1' target='_blank' className='btn btn-md btn-light btn-outline-dark d-flex justify-content-center align-items-center w-100'>
                                <img src={youtube_logo} className='w-25 h-25 mr-2'/> Subscribe
                            </a>
                        </div>
                    </div>

                    <div className="card shadow mb-4">
                        <div className="card-header pb-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold text-primary">Video Lessons</h6>
                        </div>
                        <div className="card-body" style={{height:"400px", overflow:"auto"}}>
                            {isResultPending && <center><img src={logo} alt='logo' style={{ width: "30%", height: "25%", opacity: "25%" }} /><br /><b>Loading Lesson List...</b></center>}
                            {  
                                result && <VideoList 
                                currentVideoId={videoId}
                                allVideos={result.orderedVideos}
                                combinedCourses={combinedCourses}
                                onVideoSelect={handleVideoSelect}
                                isDisabled={testTaken}
                                />
                            }
                            {
                                resultError && <center><img src={logo} alt='logo' style={{ width: "30%", height: "25%", opacity: "25%" }} /><br /><b>Loading Lesson List...</b></center>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LessonView;
