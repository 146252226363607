import React, { useState, useEffect } from "react";
import { Card, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

const LessonCard = ({ userData }) => {
  const [coursesGrouped, setCoursesGrouped] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const processUserData = () => {
        try {
          const videosWatched = userData?.vids_watched || [];
          const curCourses = userData?.cur_courses_id || []; // User's registered courses
      
          // Group courses by main_course_id
          const courses = curCourses.reduce((acc, course) => {
            const mainCourseId = course._id; // Use course_combine_with if available
            if (!acc[mainCourseId]) {
              acc[mainCourseId] = {
                mainCourseTitle: course.title || "Uncategorized",
                videos: [],
                shared: false,
              };
            }
            return acc;
          }, {});
      
          // Find the first video for each course
          curCourses.forEach((course) => {
            const mainCourseId = course.course_combine_with?._id || course._id;
            const firstMatchingVideo = videosWatched.find(
              (video) => video.main_course_id?._id === mainCourseId
            );
      
            if (firstMatchingVideo && courses[mainCourseId]) {
              courses[mainCourseId].videos.push(firstMatchingVideo);
            }
          });
      
          // Check for shared first videos
          const videoUsageCount = {};
          Object.values(courses).forEach((course) => {
            const firstVideoId = course.videos[0]?._id;
            if (firstVideoId) {
              videoUsageCount[firstVideoId] = (videoUsageCount[firstVideoId] || 0) + 1;
            }
          });
      
          Object.values(courses).forEach((course) => {
            const firstVideoId = course.videos[0]?._id;
            if (videoUsageCount[firstVideoId] > 1) {
              course.shared = true;
            }
          });
      
          setCoursesGrouped(Object.values(courses));
        } catch (error) {
          console.error("Error processing user data:", error);
        } finally {
          setLoading(false);
        }
      };
      

    processUserData();
  }, [userData]);

  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  }

  return (
    <div className="container mt-4">
      <h3>Your Courses</h3>
      <div className="row">
        {coursesGrouped.map((course, index) => (
          <div className="col-md-4" key={index}>
            <Card className="mb-4 shadow-sm">
              <Card.Body>
                <Card.Title>
                  {course.mainCourseTitle}
                  {course.shared && (
                    <small className="text-muted"> (Shared First Lesson)</small>
                  )}
                </Card.Title>
                {course.videos.length > 0 ? (
                  <Link
                    to={`/lesson/${course.videos[0]._id}/${course.videos[0].main_course_id._id}`} // Link to the first video
                    className="btn btn-primary"
                  >
                    Start Course
                  </Link>
                ) : (
                  <span className="text-muted">No videos available</span>
                )}
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LessonCard;
